import React from "react";
import ReactDOM from "react-dom";

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { CookiesProvider } from 'react-cookie';
import App from './containers/App';
import configureStore, {history} from "./store";
import rootSaga from './actions/index';

const store = configureStore( window.REDUX_DATA );
store.runSaga(rootSaga);

const jsx = (
    <CookiesProvider>
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App store={store} isLk={true}/>
            </ConnectedRouter>
        </Provider>
    </CookiesProvider>
);

const app = document.getElementById('root');

ReactDOM.hydrate( jsx, app );
